@mixin white-gradient {
	background: linear-gradient(to right,  rgba(255,255,255,1) 0%,rgba(255,255,255,0) 100%);
}
@keyframes scroll {
	0% { transform: translateX(0); }
	100% { transform: translateX(calc(-250px * 7))}
}

.slide-track {
  display: flex;
}
.slide{
    animation: scroll 40s linear infinite;
    padding:0 30px;
}
.product{
	width: 250px;
	height: 200px;
}

