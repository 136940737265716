.topArrow{
    position:fixed;
    bottom: 9rem;
    right:1rem;
    background:transparent;
    z-index: 999;
    cursor: pointer;
  }
  .topArrowimg{
    border-radius: 5%;
    width:60px;
  }