.whatsapp{
    position:fixed;
    bottom: 1rem;
    right:1rem;
    background:transparent;
    cursor: pointer;
}
.whatsappimg{
    border-radius: 50%;
    width:50px;
}
.callus{
    position:fixed;
    bottom: 5rem;
    right:1rem;
    background:transparent;
    cursor: pointer;
}
.callusimg{
    border-radius: 5%;
    width:50px;
}